// import 'react-toastify/dist/ReactToastify.css';
import "../App.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import MissionPlannerMap from "../components/MissionPlannerMap";
import {
  DefaultCoordinates,
  Mission,
  Organization,
  Sled,
  SledGroup,
  Waypoint,
} from "../C2Client";
import { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Navbar from "../components/Navbar";
import { useSearchParams } from "react-router-dom";

const waitConditions = ["None", "Operator", "External Trigger"];
const waitReleases = ["None", "Continue", "Crosswalk Clear"];

const waypointTypes = ["Begin", "Staging", "Normal", "Turnaround", "End"];
const turnTypes = ["Pivot", "Arc"];
const missedHeartbeatActions = ["None", "Pause"];

const mcityCoordinates = {
  lng: -83.698,
  lat: 42.299995,
  zoom: 18,
};

function MissionPlanner() {
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [organizations, setOrganizations] = useState<any | null>(null);
  const [organizationID, setOrganizationID] = useState<string | null>(null);
  const [organization, setOrganization] = useState<any | null>(null);
  const [sledGroups, setSledGroups] = useState<any | null>(null);
  const [sledGroupID, setSledGroupID] = useState<string | null>(null);
  const [sledGroup, setSledGroup] = useState<SledGroup | null>(null);
  const [missions, setMissions] = useState<any | null>(null);
  const [missionID, setMissionID] = useState<string | null>(null);
  const [filteredMissions, setFilteredMissions] = useState<Mission[] | null>(
    null
  );
  const [mission, setMission] = useState<Mission | null>(null);
  const [newMission, setNewMission] = useState<Mission | null>(null);
  const [waypoint, setWaypoint] = useState<Waypoint | null>(null);
  const [isNewMission, setIsNewMission] = useState(false);
  const [isRenamingMission, setIsRenamingMission] = useState(false);
  const [defaultCoordinates, setDefaultCoordinates] =
    useState<DefaultCoordinates>(mcityCoordinates);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;

      try {
        // const apiAccessToken = await getAccessTokenWithPopup({
        //   authorizationParams: {
        //     audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        //     scope: "read:current_user offline_access",
        //   },
        // });

        const apiAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user offline_access",
          },
        });

        // console.log({ apiAccessToken });
        setApiToken(apiAccessToken || "");

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
            scope: "openid profile email offline_access read:current_user",
          },
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();

        setUserMetadata(user_metadata);
      } catch (e: any) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (!apiToken) return;

    const fetchOrgs = async () => {
      fetch(`${process.env.REACT_APP_API}/organizations`, {
        headers: { Authorization: `Bearer ${apiToken}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setOrganizations(data);
        });
    };

    const fetchSledGroups = async (organizationID: string) => {
      const res = await fetch(
        `${process.env.REACT_APP_API}/sled-groups?OrganizationID=${organizationID}`
      );
      const data = await res.json();
      setSledGroups(data);
    };

    const fetchMissions = async (
      organizationID: string,
      sledGroupID: string
    ) => {
      const res = await fetch(
        `${process.env.REACT_APP_API}/missions?OrganizationID=${organizationID}&SledGroupID=${sledGroupID}`
      );
      const data = await res.json();
      setMissions(data);
    };

    // const fetchMissions = async (organizationID: string) => {
    //   const res = await fetch(`${process.env.REACT_APP_API}/missions?OrganizationID=${organizationID}`);
    //   const data = await res.json();
    //   setMissions(data);
    // };

    fetchOrgs();
    fetchSledGroups(organizationID ? organizationID : "");
    fetchMissions(
      organizationID ? organizationID : "",
      sledGroupID ? sledGroupID : ""
    );
    // fetchMissions(organizationID ? organizationID : '');
  }, [apiToken, sledGroupID]);

  useEffect(() => {
    if (organizations == null || sledGroups == null || missions == null) return;
    // Code here will run after *every* render
    const url = new URL(window.location.href);

    let oid =
      url.searchParams.get("organizationID") ||
      organizations[0]?.organizationID;
    setOrganizationID(oid);
    const orgData = organizations.find(
      (o: Organization) => o.organizationID === oid
    );
    setOrganization(orgData as Organization);

    let gid = url.searchParams.get("sledGroupID") || sledGroups[0]?.sledGroupID;
    setSledGroupID(gid);
    const groupData = sledGroups.find((g: SledGroup) => g.sledGroupID === gid);
    if (groupData) {
      setSledGroup(groupData);
      setDefaultCoordinates({
        lat: groupData.defaultLatitude,
        lng: groupData.defaultLongitude,
        zoom: 18,
      });
    }

    let mid = url.searchParams.get("missionID") || missions[0]?.missionID;
    setMissionID(mid);
    const missionData = missions.find((m: Mission) => m.missionID === mid);
    if (missionData) {
      setMission(missionData);
      // window.history.replaceState(null, '', `/planner?organizationID=${oid}&sledGroupID=${gid}&missionID=${mid}`);
      setSearchParams({
        organizationID: oid,
        sledGroupID: gid,
        missionID: mid,
      });
    } else {
      // window.history.replaceState(null, '', `/planner?organizationID=${oid}&sledGroupID=${gid}`);
      setSearchParams({ organizationID: oid, sledGroupID: gid });
    }
  }, [window.location.href, organizations, sledGroups, sledGroup, missions]);

  const changeMission = (mission: string) => {
    const m = missions.find((m: Mission) => m.missionID === mission) as Mission;
    console.log("changeMission", mission, m);
    setMission(m);
    setSearchParams({
      organizationID: organizationID!,
      sledGroupID: sledGroupID!,
      missionID: m.missionID,
    });
  };

  const changeSledGroup = (sledGroup: string) => {
    const sg = sledGroups.find(
      (g: SledGroup) => g.sledGroupID === sledGroup
    ) as SledGroup;
    // console.log("changeSledGroup", { sledGroups, sledGroup, sg });
    setSledGroup(sg);

    setDefaultCoordinates({
      lat: sg.defaultLatitude,
      lng: sg.defaultLongitude,
      zoom: 18,
    });

    const m = missions.find(
      (m: Mission) => m.sledGroupID === sledGroup
    ) as Mission;
    if (m) {
      setMission(m);
      setSearchParams({
        organizationID: organizationID!,
        sledGroupID: sg.sledGroupID!,
        missionID: m.missionID,
      });
    } else {
      setSearchParams({
        organizationID: organizationID!,
        sledGroupID: sg.sledGroupID!,
      });
    }
  };

  const changeNewMissionSledGroup = (sledGroup: string) => {
    const sg = sledGroups.find(
      (g: SledGroup) => g.sledGroupID === sledGroup
    ) as SledGroup;
    newMission!.sledGroupID = sg.sledGroupID;
    setNewMission({ ...newMission } as Mission);
  };

  const handleNewMissionClick = () => {
    setIsNewMission(true);
    const m = {} as Mission;
    m.organizationID = organizationID!;
    m.sledGroupID = sledGroupID!;
    m.missionName = "New Mission";
    m.defaultSpeedMS = 1;
    m.executions = 1;
    m.statusUpdateHz = 2;
    m.startRadiusMeters = 1;
    m.missedHeartbeatAction = missedHeartbeatActions[0];
    m.waypoints = [];
    setNewMission(m);
    setMission({ ...mission, waypoints: [] } as Mission);
  };

  const handleClick = (e: any) => {
    let newWP = {} as Waypoint;

    if (waypoint !== null) {
      newWP = waypoint;
    } else {
      let waypointType = "Normal";
      let turnType = "Arc";
      let waitCondition = "None";
      let resumeCondition = "None";

      switch (mission?.waypoints.length as number) {
        case 0:
          waypointType = "Begin";
          turnType = "Pivot";
          break;
        case 1:
          waypointType = "Staging";
          turnType = "Pivot";
          waitCondition = "Operator";
          resumeCondition = "Continue";
          break;
        default:
          waypointType = "Normal";
          turnType = "Arc";
          break;
      }

      newWP.waypointID = crypto.randomUUID();
      newWP.waypointNumber = (mission?.waypoints.length as number) + 1;
      newWP.waypointType = waypointType;
      newWP.turnType = turnType;
      newWP.speedMS = mission?.defaultSpeedMS as number;
      newWP.waitCondition = waitCondition;
      newWP.resumeCondition = resumeCondition;
    }

    newWP.longitude = e.lngLat.lng;
    newWP.latitude = e.lngLat.lat;

    const newMissions = missions.filter(
      (m: Mission) => m.missionID !== mission?.missionID
    );
    const m = { ...mission } as Mission;

    if (waypoint !== null) {
      const filtered = m.waypoints?.filter(
        (wp) => wp.waypointID !== newWP.waypointID
      );
      filtered.push(newWP);
    } else {
      m.waypoints?.push(newWP);
    }

    newMissions.push(m);
    setMissions(newMissions);
    setMission(m);
  };

  const handleCondition = (condition: string, value: string, i: number) => {
    const newWaypoints = [...(mission?.waypoints as Waypoint[])];
    const newMission = { ...mission } as Mission;
    if (condition === "wait") {
      newWaypoints[i].waitCondition = value;
    }
    if (condition === "resume") {
      newWaypoints[i].resumeCondition = value;
    }
    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };

  const handleDelete = (i: number) => {
    const newWaypoints = [...(mission?.waypoints as Waypoint[])];
    const newMission = { ...mission } as Mission;
    newWaypoints.splice(i, 1);

    for (let index = 0; index < newWaypoints.length; index++) {
      newWaypoints[index].waypointNumber = index + 1;
    }

    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    const newMissions = [...missions].filter(
      (m) => m.missionID !== newMission.missionID
    );
    newMissions.push(newMission);
    setMission(newMission);
    setMissions(newMissions);
  };

  const handleWaypointType = (value: string, i: number) => {
    const newWaypoints = [...(mission?.waypoints as Waypoint[])];
    const newMission = { ...mission } as Mission;
    newWaypoints[i].waypointType = value;
    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };

  const handleTurnType = (value: string, i: number) => {
    const newWaypoints = [...(mission?.waypoints as Waypoint[])];
    const newMission = { ...mission } as Mission;
    newWaypoints[i].turnType = value;
    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };

  const handleSpeed = (value: number, i: number) => {
    const newWaypoints = [...(mission?.waypoints as Waypoint[])];
    const newMission = { ...mission } as Mission;
    newWaypoints[i].speedMS = value;
    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };

  const handleWaypointNumber = (value: number, i: number) => {
    const newWaypoints = [...(mission?.waypoints as Waypoint[])];
    const newMission = { ...mission } as Mission;
    
    const desiredIndex = value - 1;
    const wpToMove = newWaypoints.splice(i, 1)[0];
    newWaypoints.splice(desiredIndex, 0, wpToMove);

    // console.log({ value, desiredIndex, i, wpToMove, newWaypoints });

    for (let index = 0; index < newWaypoints.length; index++) {
      newWaypoints[index].waypointNumber = index + 1;
    }

    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };

  const handleSave = (mission: Mission) => {
    if (!apiToken) return;

    const saveMission = async (m: Mission) => {
      if (m.waypoints.length < 2) {
        alert("Need Begin and End waypoints");
        return;
      }

      for (let index = 0; index < m.waypoints.length; index++) {
        m.waypoints[index].waypointNumber = index + 1;
        m.waypoints[index].turnType = m.waypoints[index].turnType
          ? m.waypoints[index].turnType
          : "None";
        m.waypoints[index].waypointType = m.waypoints[index].waypointType
          ? m.waypoints[index].waypointType
          : "None";

        if (index === 0) {
          m.waypoints[index].turnType = "None";
          m.waypoints[index].waypointType = "Begin";
          m.waypoints[index].waitCondition = "None";
          m.waypoints[index].resumeCondition = "None";
        }

        // if (index === 1) {
        //   m.waypoints[index].turnType = "None";
        //   m.waypoints[index].waypointType = "Staging";
        //   m.waypoints[index].waitCondition = "Operator";
        //   m.waypoints[index].resumeCondition = "Continue";
        // }

        if (index === m.waypoints.length - 1) {
          m.waypoints[index].turnType = "None";
          m.waypoints[index].waypointType = "End";
          m.waypoints[index].waitCondition = "None";
          m.waypoints[index].resumeCondition = "None";
        }
        // console.log(m.waypoints[index]);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API}/missions/${m.missionID}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(m),
        }
      );
      const result = await response.json();
      setMission(result);
    };

    saveMission(mission);
  };

  const handleSaveNewMission = (mission: Mission) => {
    if (!apiToken) return;

    const saveMission = async (m: Mission) => {
      for (let index = 0; index < m.waypoints.length; index++) {
        m.waypoints[index].waypointNumber = index + 1;
      }

      const response = await fetch(`${process.env.REACT_APP_API}/missions`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(m),
      });
      const result = (await response.json()) as Mission;
      window.history.replaceState(
        null,
        "",
        `/planner?organizationID=${organizationID}&sledGroupID=${result.sledGroupID}&missionID=${result.missionID}`
      );

      window.location.reload();
    };

    saveMission(mission);
  };

  const handleCancel = (mission: Mission) => {
    const getMission = async (m: Mission) => {
      const response = await fetch(
        `${process.env.REACT_APP_API}/missions/${m.missionID}`
      );
      const result = await response.json();
      setMission(result);
    };

    getMission(mission);
  };

  const handleDeleteMission = (mission: Mission) => {
    const deleteMission = async (m: Mission) => {
      const response = await fetch(`${process.env.REACT_APP_API}/missions/${m.missionID}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "application/json",
          }
        }
      );
      const result = await response.status;
      // console.log(`delete mission status: ${result}`);
      const newMissions = [...missions].filter(miss => miss?.missionID !== m.missionID);
      setMissions(newMissions);
      if (newMissions.length > 1) {
        setMission(newMissions[0]);
      } else {
        handleNewMissionClick();
      }
    };

    deleteMission(mission);
  };

  return (
    <>
      <Navbar organization={organization} />
      <div className="">
        <div className="row g-0">
          <div className="col-12">
            <MissionPlannerMap
              defaultCoordinates={defaultCoordinates}
              mission={mission}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="row g-0">
          <div className="col-12">
            <div className="row g-0">
              <div className="col-12 col-lg-2">
                <div className="p-2 border-end border-dark h-100">
                  <div className="d-flex flex-column">
                    <div className="mb-2">
                      <button
                        className="btn btn-success w-100"
                        onClick={() => handleNewMissionClick()}
                        // onClick={() => showToast()}
                      >
                        New Mission
                      </button>
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2">
                      <label className="form-label">Sled Group</label>
                      {!isNewMission ? (
                        <select
                          className="form-select"
                          aria-label="Sled Group Select"
                          placeholder="Sled Group"
                          value={sledGroup?.sledGroupID}
                          onChange={(e) => changeSledGroup(e.target.value)}
                        >
                          {sledGroups &&
                            sledGroups.map((g: SledGroup) => (
                              <option key={g.sledGroupID} value={g.sledGroupID}>
                                {g.sledGroupName}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          aria-label="New Mission Sled Group Select"
                          placeholder="Sled Group"
                          value={newMission?.sledGroupID}
                          onChange={(e) =>
                            changeNewMissionSledGroup(e.target.value)
                          }
                        >
                          {sledGroups &&
                            sledGroups.map((g: SledGroup) => (
                              <option key={g.sledGroupID} value={g.sledGroupID}>
                                {g.sledGroupName}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2">
                      <label className="form-label">Mission</label>
                      {!isNewMission && !isRenamingMission ? (
                        <select
                          className="form-select"
                          aria-label="Mission Select"
                          placeholder="Mission"
                          value={mission?.missionID}
                          onChange={(e) => {
                            changeMission(e.target.value);
                          }}
                        >
                          {missions &&
                            missions.map((m: Mission) => (
                              <option key={m.missionID} value={m.missionID}>
                                {m.missionName}
                              </option>
                            ))}
                        </select>
                      ) : !isRenamingMission ? (
                        <input
                          className="form-control"
                          type="text"
                          value={newMission?.missionName}
                          onChange={(e) => {
                            setNewMission({
                              ...newMission,
                              missionName: e.target.value,
                            } as Mission);
                          }}
                        />
                      ) : (
                        <input
                          className="form-control"
                          type="text"
                          value={mission?.missionName}
                          onChange={(e) => {
                            setMission({
                              ...mission,
                              missionName: e.target.value,
                            } as Mission);
                          }}
                        />
                      )}
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2 mt-2">
                      <button
                        className="btn btn-warning w-100"
                        onClick={() => setIsRenamingMission(!isRenamingMission)}
                        // onClick={() => showToast()}
                      >
                        Rename Mission
                      </button>
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2">
                      <label className="form-label">Default Speed (m/s)</label>
                      {!isNewMission ? (
                        <input
                          className="form-control"
                          type="number"
                          value={mission?.defaultSpeedMS}
                          onChange={(e) => {
                            setMission({
                              ...mission,
                              defaultSpeedMS: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      ) : (
                        <input
                          className="form-control"
                          type="number"
                          value={newMission?.defaultSpeedMS}
                          onChange={(e) => {
                            setNewMission({
                              ...newMission,
                              defaultSpeedMS: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      )}
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2">
                      <label className="form-label">Executions</label>
                      {!isNewMission ? (
                        <input
                          className="form-control"
                          type="number"
                          value={mission?.executions}
                          onChange={(e) => {
                            setMission({
                              ...mission,
                              executions: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      ) : (
                        <input
                          className="form-control"
                          type="number"
                          value={newMission?.executions}
                          onChange={(e) => {
                            setNewMission({
                              ...newMission,
                              executions: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      )}
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2">
                      <label className="form-label">Status Updates (Hz)</label>
                      {!isNewMission ? (
                        <input
                          className="form-control"
                          type="number"
                          value={mission?.statusUpdateHz}
                          onChange={(e) => {
                            setMission({
                              ...mission,
                              statusUpdateHz: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      ) : (
                        <input
                          className="form-control"
                          type="number"
                          value={newMission?.statusUpdateHz}
                          onChange={(e) => {
                            setNewMission({
                              ...newMission,
                              statusUpdateHz: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      )}
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2">
                      <label className="form-label">
                        Start Radius (Meters)
                      </label>
                      {!isNewMission ? (
                        <input
                          className="form-control"
                          type="number"
                          value={mission?.startRadiusMeters}
                          onChange={(e) => {
                            setMission({
                              ...mission,
                              startRadiusMeters: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      ) : (
                        <input
                          className="form-control"
                          type="number"
                          value={newMission?.startRadiusMeters}
                          onChange={(e) => {
                            setNewMission({
                              ...newMission,
                              startRadiusMeters: parseInt(e.target.value),
                            } as Mission);
                          }}
                        />
                      )}
                    </div>

                    <div className="flex-shrink-0 me-2 mb-2">
                      <label className="form-label">
                        Missed Heartbeat Action
                      </label>
                      {!isNewMission ? (
                        <select
                          className="form-select"
                          aria-label="Missed Heartbeat Action Select"
                          placeholder="Missed Heartbeat Action"
                          value={mission?.missedHeartbeatAction}
                          onChange={(e) => {
                            console.log(
                              e.target.value,
                              mission?.missedHeartbeatAction
                            );
                            setMission({
                              ...mission,
                              missedHeartbeatAction: e.target.value,
                            } as Mission);
                          }}
                        >
                          {missedHeartbeatActions &&
                            missedHeartbeatActions.map((a: string) => (
                              <option key={a} value={a}>
                                {a}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          aria-label="New Mission Missed Heartbeat Action Select"
                          placeholder="Missed Heartbeat Action"
                          value={newMission?.missedHeartbeatAction}
                          onChange={(e) => {
                            setNewMission({
                              ...newMission,
                              missedHeartbeatAction: e.target.value,
                            } as Mission);
                          }}
                        >
                          {missedHeartbeatActions &&
                            missedHeartbeatActions.map((a: string) => (
                              <option key={a} value={a}>
                                {a}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>
                    <div className="mt-2">
                      <button
                        className="btn btn-success w-100 mb-2"
                        onClick={() => {
                          isNewMission
                            ? handleSaveNewMission(newMission as Mission)
                            : handleSave(mission as Mission);
                        }}
                      >
                        Save Mission
                      </button>
                      <button
                        className="btn btn-outline-secondary w-100 mb-4"
                        onClick={() =>
                          handleCancel(
                            isNewMission
                              ? (newMission as Mission)
                              : (mission as Mission)
                          )
                        }
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-danger w-100 mb-2"
                        disabled={isNewMission}
                        onClick={() =>
                          handleDeleteMission(mission as Mission)
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  {/* <div className="align-self-end text-nowrap">
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        isNewMission ? handleSaveNewMission(newMission as Mission) : handleSave(mission as Mission)
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-outline-secondary mx-2"
                      onClick={() => handleCancel(isNewMission ? newMission as Mission : mission as Mission)}
                    >
                      Cancel
                    </button>
                  </div> */}
                </div>
              </div>
              <div
                className="col-12 col-lg-10"
                style={{ maxHeight: window.innerWidth - 772 }}
              >
                <table className="table">
                  <thead style={{ height: "50px" }}>
                    <tr>
                      <th scope="col">Waypoint #</th>
                      <th scope="col">Longitude</th>
                      <th scope="col">Latitude</th>
                      <th scope="col">Waypoint Type</th>
                      <th scope="col">Turn Type</th>
                      <th scope="col">Speed to Next WP (m/s)</th>
                      <th scope="col">Wait Condition at WP</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mission &&
                      mission.waypoints.map((wp: Waypoint, i) => (
                        <tr
                          key={mission.missionID + "-" + wp.waypointID}
                          onClick={() =>
                            !!waypoint ? setWaypoint(null) : setWaypoint(wp)
                          }
                          className={
                            wp.waypointID === waypoint?.waypointID
                              ? "table-active"
                              : ""
                          }
                        >
                          {/* <th scope="row">{i + 1}</th> */}
                          <td>
                            <input
                              className="form-control me-2"
                              type="number"
                              value={i + 1}
                              onChange={(e) =>
                                handleWaypointNumber(parseInt(e.target.value), i)
                              }
                            />
                          </td>
                          <td>{wp.longitude}</td>
                          <td>{wp.latitude}</td>
                          <td>
                            <div className="d-inline-flex">
                              <select
                                className="form-select me-2"
                                aria-label="Waypoint Type Select"
                                placeholder="Waypoint Type"
                                value={wp?.waypointType}
                                onChange={(e) =>
                                  handleWaypointType(e.target.value, i)
                                }
                              >
                                {waypointTypes &&
                                  waypointTypes.map((t) => (
                                    <option key={t} value={t}>
                                      {t}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="d-inline-flex">
                              {wp.waypointType === "Normal" ? (
                                <select
                                  className="form-select me-2"
                                  aria-label="Turn Type Select"
                                  placeholder="Turn Type"
                                  value={wp?.turnType}
                                  onChange={(e) =>
                                    handleTurnType(e.target.value, i)
                                  }
                                >
                                  {turnTypes &&
                                    turnTypes.map((t) => (
                                      <option key={t} value={t}>
                                        {t}
                                      </option>
                                    ))}
                                </select>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </td>
                          <td>
                            {wp?.waypointNumber < mission.waypoints.length ? (
                              <input
                                className="form-control me-2"
                                type="number"
                                value={wp.speedMS}
                                onChange={(e) =>
                                  handleSpeed(parseFloat(e.target.value), i)
                                }
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            <div className="d-inline-flex">
                              {wp?.waypointNumber < mission.waypoints.length ? (
                                <>
                                  <select
                                    className="form-select me-2"
                                    aria-label="Wait Condition Select"
                                    placeholder="Wait Condition"
                                    value={wp?.waitCondition}
                                    onChange={(e) =>
                                      handleCondition("wait", e.target.value, i)
                                    }
                                  >
                                    {waitConditions &&
                                      waitConditions.map((c) => (
                                        <option key={c} value={c}>
                                          {c}
                                        </option>
                                      ))}
                                  </select>
                                  <select
                                    className="form-select"
                                    aria-label="Wait Release Select"
                                    placeholder="Wait Release"
                                    value={wp?.resumeCondition}
                                    onChange={(e) =>
                                      handleCondition(
                                        "resume",
                                        e.target.value,
                                        i
                                      )
                                    }
                                  >
                                    {waitReleases &&
                                      waitReleases.map((c) => (
                                        <option key={c} value={c}>
                                          {c}
                                        </option>
                                      ))}
                                  </select>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDelete(i)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withAuthenticationRequired(MissionPlanner);
