import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "../App.scss";
import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import {
  ControlData,
  ControlParams,
  Organization,
  Sled,
  SledGroup,
} from "../C2Client";

const sledTypes = ["Sled", "Car", "Deer", "Pedestrian"];

function Settings() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [organizations, setOrganizations] = useState<any | null>(null);
  const [organizationID, setOrganizationID] = useState<string | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [apiVersion, setApiVersion] = useState<string | null>(null);
  const [controlParams, setControlParams] = useState<ControlParams | null>(
    null
  );
  const [controlData, setControlData] = useState<ControlData | null>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [sledGroups, setSledGroups] = useState<SledGroup[] | null>(null);
  const [sledGroup, setSledGroup] = useState<SledGroup | null>(null);
  const [sleds, setSleds] = useState<Sled[] | null>(null);
  const [userZoom, setUserZoom] = useState<boolean>(true);

  useEffect(() => {
    const lsc = localStorage.getItem("userZoom");
    if (lsc !== null) {
      setUserZoom(lsc === "true");
    } else {
      localStorage.setItem("userZoom", "false");
      setUserZoom(false);
    }
  }, []);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;

      try {
        const apiAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user offline_access",
          },
        });

        // console.log({ apiAccessToken });
        setApiToken(apiAccessToken || "");

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
            scope: "openid profile email offline_access read:current_user",
          },
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();

        setUserMetadata(user_metadata);
      } catch (e: any) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (!apiToken) return;

    const fetchOrgs = async () => {
      fetch(`${process.env.REACT_APP_API}/organizations`, {
        headers: { Authorization: `Bearer ${apiToken}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setOrganizations(data);
        });
    };

    fetchOrgs();
  }, [apiToken]);

  useEffect(() => {
    if (!apiToken) return;

    const url = new URL(window.location.href);

    let organizationID = url.searchParams.get("organizationID");

    const fetchDefaultParams = async () => {
      const paramResponse = await fetch(
        `${process.env.REACT_APP_API}/defaults`,
        {
          headers: { Authorization: `Bearer ${apiToken}` },
        }
      );
      const params = (await paramResponse.json()) as ControlParams;

      console.log("DefaultParams: ", params);
      window.history.replaceState(
        null,
        "",
        `/settings?organizationID=${params.organizationID}`
      );
      setControlParams(params);
    };

    if (organizationID) {
      const params = { organizationID } as ControlParams;
      console.log("SearchParams: ", params);
      setControlParams(params);
    } else {
      fetchDefaultParams();
    }
  }, [apiToken]);

  useEffect(() => {
    if (!controlParams) return;

    const fetchControlData = async (organizationID: string) => {
      const dataResponse = await fetch(
        `${process.env.REACT_APP_API}/control-data?OrganizationID=${organizationID}`
      );
      const data = await dataResponse.json();

      setOrganization(data.organization);
      setSledGroups(data.sledGroups);
      setSleds(data.sleds);
      setControlData(data);
      setDataLoaded(true);
    };

    fetchControlData(controlParams.organizationID);
  }, [controlParams?.organizationID]);

  useEffect(() => {
    if (organizations == null) return;
    // Code here will run after *every* render
    const url = new URL(window.location.href);

    let oid =
      url.searchParams.get("organizationID") ||
      organizations[0]?.organizationID;
    setOrganizationID(oid);
    const orgData = organizations.find(
      (o: Organization) => o.organizationID === oid
    );
    setOrganization(orgData as Organization);

    window.history.replaceState(null, "", `/settings?organizationID=${oid}`);
  }, [window.location.href, organizations]);

  useEffect(() => {
    const fetchVersion = async () => {
      fetch(`${process.env.REACT_APP_API}/version`)
        .then((res) => res.text())
        .then((version) => {
          setApiVersion(version);
        });
    };

    fetchVersion();
  }, []);

  const handleSave = (sled: Sled) => {
    if (!apiToken || !sleds) return;

    const saveSled = async (s: Sled) => {
      const response = await fetch(
        `${process.env.REACT_APP_API}/sleds/${s.sledID}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(s),
        }
      );
      const result = await response.json();

      const newSleds = [...sleds].filter(
        (current) => current.sledID !== s?.sledID
      );

      newSleds.push(s);

      setSleds(newSleds);
    };

    saveSled(sled);
  };

  const handleSledName = (value: string, i: number) => {
    const newSleds = [...(sleds as Sled[])];
    newSleds[i].sledName = value;
    setSleds(newSleds);
  };

  const changeSledGroup = (value: string, i: number) => {
    const newSleds = [...(sleds as Sled[])];
    newSleds[i].sledGroupID = value;
    setSleds(newSleds);
  };

  const changeSledType = (value: string, i: number) => {
    const newSleds = [...(sleds as Sled[])];
    newSleds[i].sledType = value;
    setSleds(newSleds);
  };

  return (
    <>
      <Navbar organization={organization} />
      <div className="">
        <div className="row g-0">
          <div className="col-2 p-2"></div>
          <div className="col-8 p-2 mt-5">
            {/* <h3>User Settings</h3>
            <button
              className={`btn btn-sm ${userZoom ? "btn-primary" : "btn-secondary"} w-100`}
              onClick={() => {
                localStorage.setItem("userZoom", JSON.stringify(!userZoom));
                setUserZoom(!userZoom);
              }}
            >
              {JSON.stringify(userZoom)}
            </button> */}
            <h3>Software Versions</h3>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Application</th>
                  <th scope="col">Version</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SCC Client</td>
                  <td>{process.env.REACT_APP_CLIENT_VERSION}</td>
                </tr>
                <tr>
                  <td>SCC API</td>
                  <td>{apiVersion}</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <h3>Sleds</h3>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sled ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Group</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sleds &&
                  sleds.map((sled: Sled, i) => (
                    <tr key={sled.sledID}>
                      <th
                        onCopy={(e) => {
                          e.preventDefault();
                          console.log(e.currentTarget.nodeValue, e);
                          e.clipboardData.setData("text/plain", sled.sledID);
                        }}
                        onDoubleClick={(e) => {
                          e.preventDefault();
                          // const data = new DataTransfer().setData("text/plain", sled.sledID);
                          // new ClipboardEvent('copy')?.clipboardData.setData("text/plain", sled.sledID);
                        }}
                      >
                        {sled.sledID.toLowerCase()}
                      </th>
                      <td>
                        <input
                          className="form-control me-2"
                          type="text"
                          value={sled.sledName}
                          onChange={(e) => handleSledName(e.target.value, i)}
                        />
                      </td>
                      <td>
                        <div className="d-inline">
                          <select
                            className="form-select"
                            aria-label="Sled Type Select"
                            placeholder="Sled Type"
                            value={sled?.sledType}
                            onChange={(e) => changeSledType(e.target.value, i)}
                          >
                            {sledTypes &&
                              sledTypes.map((t: string) => (
                                <option key={t} value={t}>
                                  {t}
                                </option>
                              ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="d-inline">
                          <select
                            className="form-select"
                            aria-label="Sled Group Select"
                            placeholder="Sled Group"
                            value={sled?.sledGroupID}
                            onChange={(e) => changeSledGroup(e.target.value, i)}
                          >
                            {sledGroups &&
                              sledGroups.map((g: SledGroup) => (
                                <option
                                  key={g.sledGroupID}
                                  value={g.sledGroupID}
                                >
                                  {g.sledGroupName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSave(sled)}
                        >
                          Save
                        </button>
                        {/* <button
                              className="btn btn-danger"
                              onClick={() => handleDelete(sled)}
                            >
                              Delete
                            </button> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="col-2 p-2"></div>
        </div>
      </div>
    </>
  );
}

export default withAuthenticationRequired(Settings);
